cs.ns('app.ui.composite.osc')
app.ui.composite.osc.model = cs.clazz({
    extend:   app.fw.abstract.model,
    dynamics: {},
    protos:   {

        create () {
            this.base()

            cs(this).model({
                'event:toggleChannelList':  { value: false, valid: 'boolean', autoreset: true },
                'event:toggleContentList':  { value: false, valid: 'boolean', autoreset: true },
                'event:toggleQuickjump':    { value: false, valid: 'boolean', autoreset: true },
                'event:toggleAccess':       { value: false, valid: 'boolean', autoreset: true },
                'event:forward':            { value: false, valid: 'boolean', autoreset: true },
                'event:pause':              { value: false, valid: 'boolean', autoreset: true },
                'event:play':               { value: false, valid: 'boolean', autoreset: true },
                'event:backward':           { value: false, valid: 'boolean', autoreset: true },
                'event:login':              { value: false, valid: 'boolean', autoreset: true },
                'event:logout':             { value: false, valid: 'boolean', autoreset: true },
                'event:directEntry':        { value: false, valid: 'boolean', autoreset: true },
                'event:requestFullscreen':  { value: false, valid: 'boolean', autoreset: true },
                'event:exitFullscreen':     { value: false, valid: 'boolean', autoreset: true },

                'data:menuContent':         { value: '', valid: 'string' },

                'state:channelVisible':     { value: true, valid: 'boolean' },
                'state:contentVisible':     { value: true, valid: 'boolean' },
                'state:controlsVisible':    { value: true, valid: 'boolean' }
            })
        }
    }
})